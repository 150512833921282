import { FC } from 'react';

import { SampleRouter } from '@route/SampleRoute';
import { Routes, Route } from 'react-router-dom';
import { MainPage } from '@containers/Main';

const RootRouter: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="sample/*" element={<SampleRouter />} />
    </Routes>
  );
};

export { RootRouter };

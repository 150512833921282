import { FC } from 'react';

import { SubscriptionCounterBtnSet, SubscriptionCounterViewer } from './components';

const HasuraCounter: FC = () => {
  return (
    <>
      <div className="w-half-1 m-2 ">
        SubscriptionCounter
        <div className="outline outline-1 w-full py-10 mt-2">
          <SubscriptionCounterViewer />
          <SubscriptionCounterBtnSet />
        </div>
      </div>
    </>
  );
};

export { HasuraCounter };

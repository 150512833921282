import { useEffect } from 'react';

import { useSetRecoilState } from 'recoil';

import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { hasuraCountQueryAtom, hasuraCountSubscriptionAtom } from '@state';

import {
  COUNT_BY_PK,
  COUNT_BY_PK_RES,
  COUNT_BY_PK_VARIABLES,
  UPDATE_COUNT_BY_PK,
  UPDATE_COUNT_BY_PK_RES,
  UPDATE_COUNT_BY_PK_VARIABLES,
  SUBSCRIPTION_COUNT_BY_PK,
  SUBSCRIPTION_COUNT_BY_PK_RES,
  SUBSCRIPTION_COUNT_BY_PK_VARIABLES,
} from './graphql';

const useApolloCount = () => {
  const setQueryCount = useSetRecoilState(hasuraCountQueryAtom);

  const useQueryHasuraCountById = (variables: COUNT_BY_PK_VARIABLES) => {
    const { data } = useQuery<COUNT_BY_PK_RES, COUNT_BY_PK_VARIABLES>(COUNT_BY_PK, {
      variables,
    });

    useEffect(() => {
      if (!data) return;
      setQueryCount(data.Count_by_pk.curr);
    }, [data]);
  };

  const [updateHasuraCountById] = useMutation<
    UPDATE_COUNT_BY_PK_RES,
    UPDATE_COUNT_BY_PK_VARIABLES
  >(UPDATE_COUNT_BY_PK);

  return {
    useQueryHasuraCountById,
    updateHasuraCountById,
  };
};

const useSubscriptionHasuraCountById = (
  variables: SUBSCRIPTION_COUNT_BY_PK_VARIABLES,
) => {
  const setSubscriptionCount = useSetRecoilState(hasuraCountSubscriptionAtom);
  const { data } = useSubscription<
    SUBSCRIPTION_COUNT_BY_PK_RES,
    SUBSCRIPTION_COUNT_BY_PK_VARIABLES
  >(SUBSCRIPTION_COUNT_BY_PK, {
    variables,
  });

  useEffect(() => {
    console.log('data: ', data);
    if (!data) return;
    setSubscriptionCount(data.Count_by_pk.curr);
  }, [data, setSubscriptionCount]);
};

export { useApolloCount, useSubscriptionHasuraCountById };

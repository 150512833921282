import { FC, useCallback } from 'react';

import { useHasuraCounter } from '@state';

const SubscriptionCounterBtnSet: FC = () => {
  const { subscriptionIncrease, subscriptionDncrease } = useHasuraCounter();

  const onPlusButtonClickHandler = useCallback(() => {
    subscriptionIncrease();
  }, [subscriptionIncrease]);

  const onMinusButtonClickHandler = useCallback(() => {
    subscriptionDncrease();
  }, [subscriptionDncrease]);

  return (
    <div className="mt-3">
      <div>SubscriptionCounterBtnSet</div>
      <button className="m-2 w-10 outline-1 outline" onClick={onPlusButtonClickHandler}>
        +
      </button>
      <button className="m-2 w-10 outline-1 outline" onClick={onMinusButtonClickHandler}>
        -
      </button>
    </div>
  );
};

export { SubscriptionCounterBtnSet };

import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { persistCountAtom } from '@state';

const PersistCounterBtnSet: FC = () => {
  const setCount = useSetRecoilState(persistCountAtom);

  const onPlusButtonClickHandler = () => {
    setCount((curr) => curr + 1);
  };

  const onMinusButtonClickHandler = () => {
    setCount((curr) => curr - 1);
  };

  return (
    <div className="mt-3">
      <div>PersistCounterBtnSet</div>
      <button className="m-2 w-10 outline-1 outline" onClick={onPlusButtonClickHandler}>
        +
      </button>
      <button className="m-2 w-10 outline-1 outline" onClick={onMinusButtonClickHandler}>
        -
      </button>
    </div>
  );
};

export { PersistCounterBtnSet };

import { FC } from 'react';

import { QueryCounterBtnSet, QueryCounterViewer } from './components';

const QueryCounter: FC = () => {
  return (
    <>
      <div className="w-half-1 m-2 ">
        QueryCounter
        <div className="outline outline-1 w-full py-10 mt-2">
          <QueryCounterViewer />
          <QueryCounterBtnSet />
        </div>
      </div>
    </>
  );
};

export { QueryCounter };

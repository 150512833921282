import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { serverHasuraCountAtom } from '@state';

const ServerHasuraCounterViewer: FC = () => {
  const count = useRecoilValue(serverHasuraCountAtom);

  return (
    <div>
      <div>ServerHasuraCounterViewer</div>
      <span>count: {count}</span>
    </div>
  );
};

export { ServerHasuraCounterViewer };

import { useSetRecoilState } from 'recoil';

import { useAsync } from 'react-use';
import { serverHasuraCountAtom } from '@state';

import { getServerHasuraCount, patchServerHasuraCount } from './api';

const useServerHasuraCounter = (id: string) => {
  const setCount = useSetRecoilState(serverHasuraCountAtom);

  useAsync(async () => {
    const { data } = await getServerHasuraCount(id);
    console.log(data);
    setCount(data);
  });

  const increase = async () => {
    const inc = 1;
    try {
      const { data } = await patchServerHasuraCount(id, inc);
      setCount(data);
    } catch (err) {
      console.error(err);
    }
  };

  const decrease = async () => {
    const inc = -1;
    try {
      const { data } = await patchServerHasuraCount(id, inc);
      setCount(data);
    } catch (err) {
      console.error(err);
    }
  };

  return { increase, decrease };
};

export { useServerHasuraCounter };

import { useRecoilValue, useSetRecoilState } from 'recoil';

import { hasuraCountQueryAtom, hasuraCountIdAtom } from '@state';

import { useApolloCount } from './apollo';

const useHasuraCounter = () => {
  const setQueryCount = useSetRecoilState(hasuraCountQueryAtom);

  const id = useRecoilValue(hasuraCountIdAtom);

  const { useQueryHasuraCountById, updateHasuraCountById } = useApolloCount();

  useQueryHasuraCountById({ id });

  const increase = async () => {
    const inc = 1;
    try {
      const { data } = await updateHasuraCountById({ variables: { id, inc } });
      //   const { data } = await patchHasuraCount(id, inc);
      if (!data) {
        throw new Error('data is undefined');
      }
      setQueryCount(data.update_Count_by_pk.curr);
    } catch (err) {
      console.error(err);
    }
  };

  const decrease = async () => {
    const inc = -1;
    try {
      const { data } = await updateHasuraCountById({ variables: { id, inc } });
      //   const { data } = await patchHasuraCount(id, inc);
      if (!data) {
        throw new Error('data is undefined');
      }
      setQueryCount(data.update_Count_by_pk.curr);
    } catch (err) {
      console.error(err);
    }
  };

  const subscriptionIncrease = async () => {
    const inc = 1;
    try {
      const { data } = await updateHasuraCountById({ variables: { id, inc } });
      //   const { data } = await patchHasuraCount(id, inc);
      if (!data) {
        throw new Error('data is undefined');
      }
    } catch (err) {
      console.error(err);
    }
  };

  const subscriptionDncrease = async () => {
    const inc = -1;
    try {
      const { data } = await updateHasuraCountById({ variables: { id, inc } });
      //   const { data } = await patchHasuraCount(id, inc);
      if (!data) {
        throw new Error('data is undefined');
      }
    } catch (err) {
      console.error(err);
    }
  };

  return { increase, decrease, subscriptionIncrease, subscriptionDncrease };
};

export { useHasuraCounter };

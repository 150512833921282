import { useSetRecoilState } from 'recoil';

import { serverCountAtom } from '@state';

import { getCountCalculate } from './api';

const useServerCounter = () => {
  const setCount = useSetRecoilState(serverCountAtom);

  const increase = async (curr: number) => {
    const action = 'increase';
    try {
      const { data } = await getCountCalculate(curr, action);
      setCount(data);
    } catch (err) {
      console.error(err);
    }
  };

  const decrease = async (curr: number) => {
    const action = 'decrease';
    try {
      const { data } = await getCountCalculate(curr, action);
      setCount(data);
    } catch (err) {
      console.error(err);
    }
  };

  return { increase, decrease };
};

export { useServerCounter };

import { FC } from 'react';

import { PostTable, SearchBar } from '@containers/Board/components';

const Board: FC = () => {
  return (
    <div>
      Board
      <PostTable />
      <SearchBar />
    </div>
  );
};

export { Board };

import { api } from '@plugin/axios';

import {
  GetServerHasuraCountResponse,
  PatchServerHasuraCountResponse,
} from './serverHasuraCounter.interface';

const getServerHasuraCount = (id: string): Promise<GetServerHasuraCountResponse> => {
  return api.get(`sample/hasura/count/${id}`);
};

const patchServerHasuraCount = (
  id: string,
  inc: number,
): Promise<PatchServerHasuraCountResponse> => {
  const body = { inc };
  return api.patch(`sample/hasura/count/${id}`, body);
};

export { getServerHasuraCount, patchServerHasuraCount };

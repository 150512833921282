import { FC, useCallback } from 'react';

import { useHasuraCounter } from '@state';

const QueryCounterBtnSet: FC = () => {
  const { increase, decrease } = useHasuraCounter();

  const onPlusButtonClickHandler = useCallback(() => {
    increase();
  }, [increase]);

  const onMinusButtonClickHandler = useCallback(() => {
    decrease();
  }, [decrease]);

  return (
    <div className="mt-3">
      <div>QueryCounterBtnSet</div>
      <button className="m-2 w-10 outline-1 outline" onClick={onPlusButtonClickHandler}>
        +
      </button>
      <button className="m-2 w-10 outline-1 outline" onClick={onMinusButtonClickHandler}>
        -
      </button>
    </div>
  );
};

export { QueryCounterBtnSet };

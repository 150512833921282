import { FC } from 'react';

import { ServerHasuraCounterBtnSet, ServerHasuraCounterViewer } from './components';

const ServerHasuraCounter: FC = () => {
  return (
    <>
      <div className="w-half-1 m-2 ">
        ServerHasuraCounter
        <div className="outline outline-1 w-full py-10 mt-2">
          <ServerHasuraCounterBtnSet />
          <ServerHasuraCounterViewer />
        </div>
      </div>
    </>
  );
};

export { ServerHasuraCounter };

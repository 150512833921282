import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { persistCountAtom } from '@state';

const PersistCounterViewer: FC = () => {
  const count = useRecoilValue(persistCountAtom);

  return (
    <div>
      <div>PersistCounterViewer</div>
      <span>count: {count}</span>
    </div>
  );
};

export { PersistCounterViewer };

import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import {
  hasuraCountIdAtom,
  hasuraCountSubscriptionAtom,
  useSubscriptionHasuraCountById,
} from '@state';

const SubscriptionCounterViewer: FC = () => {
  const id = useRecoilValue(hasuraCountIdAtom);
  const count = useRecoilValue(hasuraCountSubscriptionAtom);

  useSubscriptionHasuraCountById({ id });

  return (
    <div>
      <div>SubscriptionCounterViewer</div>
      <span>count: {count}</span>
    </div>
  );
};

export { SubscriptionCounterViewer };

import React, { FC } from 'react';

import { RecoilRoot } from 'recoil';

import './App.css';
import { RootRouter } from '@route';
import { ApolloWrapper } from '@plugin/apollo';

const App: FC = () => {
  return (
    <RecoilRoot>
      <div className="App">
        <ApolloWrapper>
          <RootRouter />
        </ApolloWrapper>
      </div>
    </RecoilRoot>
  );
};

export default App;

import { FC } from 'react';

import { QueryCounter, HasuraCounter } from './components';

const Hasura: FC = () => {
  return (
    <>
      <div className="w-full m-2 mt-8">
        Hasura
        <div className="outline outline-1 w-full flex py-10 mt-2">
          <QueryCounter />
          <HasuraCounter />
        </div>
      </div>
    </>
  );
};

export { Hasura };

import { FC } from 'react';

import { ServerCounterBtnSet, ServerCounterViewer } from './components';

const ServerCounter: FC = () => {
  return (
    <>
      <div className="w-half-1 m-2 ">
        ServerCounter
        <div className="outline outline-1 w-full py-10 mt-2">
          <ServerCounterViewer />
          <ServerCounterBtnSet />
        </div>
      </div>
    </>
  );
};

export { ServerCounter };

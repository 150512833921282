import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { localCountAtom } from '@state';

const LocalCounterViewer: FC = () => {
  const count = useRecoilValue(localCountAtom);

  return (
    <div>
      <div>LocalCounterViewer</div>
      <span>count: {count}</span>
    </div>
  );
};

export { LocalCounterViewer };

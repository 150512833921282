import { FC } from 'react';

import { Routes, Route } from 'react-router-dom';
import { Sample } from '@containers';
import { Board } from '@containers/Board';

const SampleRouter: FC = () => {
  return (
    <Routes>
      <Route path="*" element={<Sample />} />
      <Route path="board" element={<Board />} />
    </Routes>
  );
};

export { SampleRouter };

import { FC } from 'react';

import {
  Hasura,
  LocalCounter,
  PersistCounter,
  ServerCounter,
  ServerHasuraCounter,
} from './components';

const Sample: FC = () => {
  return (
    <div className="outline outline-1 py-10 m-2">
      Sample
      <div className="flex">
        <LocalCounter />
        <PersistCounter />
      </div>
      <div className="flex">
        <ServerCounter />
        <ServerHasuraCounter />
      </div>
      <div className="flex">
        <Hasura />
      </div>
    </div>
  );
};

export { Sample };

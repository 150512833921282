import { FC } from 'react';

import { PersistCounterBtnSet, PersistCounterViewer } from './components';

const PersistCounter: FC = () => {
  return (
    <>
      <div className="w-half-1 m-2">
        PersistCounter
        <div className="outline outline-1 w-full py-10 mt-2">
          <PersistCounterViewer />
          <PersistCounterBtnSet />
        </div>
      </div>
    </>
  );
};

export { PersistCounter };

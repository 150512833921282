import { FC, useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { serverCountAtom, useServerCounter } from '@state';

const ServerCounterBtnSet: FC = () => {
  const serverCount = useRecoilValue(serverCountAtom);
  const { increase, decrease } = useServerCounter();

  const onPlusButtonClickHandler = useCallback(() => {
    increase(serverCount);
  }, [serverCount, increase]);

  const onMinusButtonClickHandler = useCallback(() => {
    decrease(serverCount);
  }, [serverCount, decrease]);

  return (
    <div className="mt-3">
      <div>ServerCounterBtnSet</div>
      <button className="m-2 w-10 outline-1 outline" onClick={onPlusButtonClickHandler}>
        +
      </button>
      <button className="m-2 w-10 outline-1 outline" onClick={onMinusButtonClickHandler}>
        -
      </button>
    </div>
  );
};

export { ServerCounterBtnSet };

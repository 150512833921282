import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { serverCountAtom } from '@state';

const ServerCounterViewer: FC = () => {
  const count = useRecoilValue(serverCountAtom);

  return (
    <div>
      <div>ServerCounterViewer</div>
      <span>count: {count}</span>
    </div>
  );
};

export { ServerCounterViewer };

import { FC } from 'react';

import { useSetRecoilState } from 'recoil';

import { localCountAtom } from '@state';

const LocalCounterBtnSet: FC = () => {
  const setCount = useSetRecoilState(localCountAtom);

  const onPlusButtonClickHandler = () => {
    setCount((curr) => curr + 1);
  };

  const onMinusButtonClickHandler = () => {
    setCount((curr) => curr - 1);
  };

  return (
    <div className="mt-3">
      <div>LocalCounterBtnSet</div>
      <button className="m-2 w-10 outline-1 outline" onClick={onPlusButtonClickHandler}>
        +
      </button>
      <button className="m-2 w-10 outline-1 outline" onClick={onMinusButtonClickHandler}>
        -
      </button>
    </div>
  );
};

export { LocalCounterBtnSet };

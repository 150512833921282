import { FC, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

const MainPage: FC = () => {
  const navigate = useNavigate();

  const sampleButtonClickHandler = useCallback(() => {
    navigate('/sample');
  }, [navigate]);

  return (
    <div>
      <button onClick={sampleButtonClickHandler}>sample</button>
    </div>
  );
};

export { MainPage };

import { GetCounterCalculateResponse } from '@containers/Sample/components/ServerCounter/state/api/serverCounter.interface';
import { api } from '@plugin/axios';

const getCountCalculate = (
  curr: number,
  action: string,
): Promise<GetCounterCalculateResponse> => {
  const params = { curr, action };
  return api.get('sample/counter/calculate', { params });
};

export { getCountCalculate };

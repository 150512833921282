import { FC, useCallback } from 'react';

import { useRecoilValue } from 'recoil';

import { serverHasuraCountIdAtom, useServerHasuraCounter } from '@state';

const ServerHasuraCounterBtnSet: FC = () => {
  const serverHasuraCountId = useRecoilValue(serverHasuraCountIdAtom);

  const { increase, decrease } = useServerHasuraCounter(serverHasuraCountId);

  const onPlusButtonClickHandler = useCallback(() => {
    increase();
  }, [increase]);

  const onMinusButtonClickHandler = useCallback(() => {
    decrease();
  }, [decrease]);

  return (
    <div className="mt-3">
      <div>ServerHasuraCounterBtnSet</div>
      <button className="m-2 w-10 outline-1 outline" onClick={onPlusButtonClickHandler}>
        +
      </button>
      <button className="m-2 w-10 outline-1 outline" onClick={onMinusButtonClickHandler}>
        -
      </button>
    </div>
  );
};

export { ServerHasuraCounterBtnSet };

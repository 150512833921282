import { FC } from 'react';

import { useRecoilValue } from 'recoil';

import { hasuraCountQueryAtom, useHasuraCounter } from '@state';

const QueryCounterViewer: FC = () => {
  const count = useRecoilValue(hasuraCountQueryAtom);

  useHasuraCounter();

  return (
    <div>
      <div>QueryCounterViewer</div>
      <span>count: {count}</span>
    </div>
  );
};

export { QueryCounterViewer };

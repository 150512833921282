import { FC } from 'react';

import { LocalCounterBtnSet, LocalCounterViewer } from './components';

const LocalCounter: FC = () => {
  return (
    <>
      <div className="w-half-1 m-2 ">
        LocalCounter
        <div className="outline outline-1 w-full py-10 mt-2">
          <LocalCounterViewer />
          <LocalCounterBtnSet />
        </div>
      </div>
    </>
  );
};

export { LocalCounter };
